<template>
  <div>
      <DecimalToByte/>
  </div>
</template>

<script>
import DecimalToByte from "@/components/byte-math/DecimalToByte.vue";

export default {
  components: {
    DecimalToByte,
  },
};
</script>

<style>
</style>
